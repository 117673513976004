<template>
	<section class="duck">
        <img class="duck__img" :src="Duck.thumbnail"/>
        <h3 class="duck__heading">{{Duck.title}}</h3>
        <span class="duck__rank">Rank: {{Duck.rank}}</span>
        <span class="duck__rate">Stake rate: {{Duck.stake.rate}}x</span>

        <div class="duck__footer">
            <template v-if="Duck.stake.status=='inactive'">
                <span class="btn btn--multicolor" @click="$emit('stake', Duck.mintToken, 'BEAK')">Stake</span>
                <!-- <span class="btn btn--unstake" @click="$emit('fix', Duck.mintToken)" v-if="Duck.isFrozen!=true">Fix NFT</span> -->
            </template>
            
            <template v-if="Duck.stake.status=='active' || Duck.stake.status=='activating'">
                <span class="btn btn--unstake" @click="$emit('unstake', Duck.mintToken)">Unstake</span>
            </template>
            
            <span class="duck__stakeActivating" v-if="Duck.stake.status=='activating'">Activating...</span>
        </div>
    </section>
</template>

<script>
export default {
	name: 'Duck',
	props: [
		"Duck"
	],
    emits: [
        "stake",
        "unstake",
        "fix"
    ]
}
</script>

<style scoped lang="scss">
.duck{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #ECECEC;
    border-radius: 20px;
    padding: 20px;
    &__img{
        width: 240px;
        border-radius: 7px;
    }
    &__heading{
        margin: 18px 0 10px 0;
        font-size: 14px;
        font-weight: 700;
    }
    &__rank, &__rate{
        font-size: 10px;
        font-weight: 700;
        color: #B9B9B9;
        margin: 5px 0;
    }
    &__footer{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
		margin-top: 10px;
        gap: 5px;
    }
    &__stakeActivating{
        font-size: 10px;
        font-weight: 700;
        color: #B9B9B9;
        margin: 5px;
    }
}
</style>