<template>
	<section class="stat">
        <h3 class="stat__heading">{{Stat.title}}</h3>
        <span class="stat__text">{{Stat.subtitle}}</span>
    </section>
</template>

<script>
export default {
	name: 'Stat',
	props: [
		"Stat"
	]
}
</script>

<style scoped lang="scss">
.stat{
	margin: 0 35px;
	&__heading{
		color: #2081E2;
		font-size: 56px;
		font-weight: 500;
		margin: 0 0 15px 0;
	}
	&__text{
		color: #989898;
		font-size: 16px;
	}
}
</style>