<template>
	<header class="header">
		<div class="header__item">
			<img src="@/assets/duck1.png" class="header__image"/>
			<span class="header__text">Danger Valley</span>
		</div>

		<div style="display: flex; gap: 12px">
			<div v-if="walletId" class="header__item" @click="this.$emit('onClickOnMyPayouts')">
				<span class="btn btn--blue">My payouts</span>
			</div>

			<div class="header__item" @click="!walletId ? this.$emit('onClickOnConnectWallet') : null">
				<span class="btn btn--blue">{{walletId || "Connect Wallet"}}</span>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	name: 'Header',
	props: [
		"walletIdProp"
	],
	data: function() {
		return {
			walletId: undefined
		}
	},
	mounted: function() {
		if(this.walletIdProp) this.formatWalletId(this.walletIdProp);
	},
	watch: {
		walletIdProp(newVal, oldVal){
			if(typeof newVal == "string"){
				this.formatWalletId(newVal);
			}
		}
	},
	methods: {
		formatWalletId(str){
			this.walletId = `${str.slice(0,4)}...${str.slice(-4)}`;
		}
	}
}
</script>

<style scoped lang="scss">
.header{
	box-sizing: border-box;
	width: 100%;
	height: 55px;
	background: #fff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	&__item{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	&__image{
		width: 32px;
		height: 32px;
		border-radius: 50%;
		margin-right: 8px;
	}
	&__text{}
}
</style>