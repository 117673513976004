class Wallet {
    constructor(path){
        this.localPath = `${path}/wallet`;
    }

    async get(walletId){
        return await fetch(`${this.localPath}/${walletId}`);
    }
}
class Stake{
    constructor(path){
        this.localPath = `${path}/staking`;
    }

    async get(walletAddress=undefined){
        let str = "";
        if(walletAddress) str = `?walletAddress=${walletAddress}`
        return await fetch(`${this.localPath}${str}`);
    }

    async stake(walletAddress, mintTokens){
        return await fetch(`${this.localPath}/stake`, {
            method: "POST",
            body: JSON.stringify({
                walletAddress: walletAddress,
                mintTokens: mintTokens,
            }),
            headers: { 'content-type': 'application/json' }
        });
    }

    async unstake(walletAddress, mintTokens){
        return await fetch(`${this.localPath}/unstake`, {
            method: "POST",
            body: JSON.stringify({
                walletAddress: walletAddress,
                mintTokens: mintTokens
            }),
            headers: { 'content-type': 'application/json' }
        });
    }
}
class Escrow{
    constructor(path){
        this.localPath = `${path}/escrow`;
    }

    async claim(ids, txs){
        return await fetch(`${this.localPath}/${ids.join(',')}/claim`, {
            method: "POST",
            body: JSON.stringify({
                transactions: txs,
            }),
            headers: { 'content-type': 'application/json' }
        });
    }

    async getStatus(ids){
        try {
            const response = await fetch(`${this.localPath}/${ids.join(',')}/status`, {
                method: "GET",
                headers: { 'content-type': 'application/json' }
            });   
            const body = await response.json(); 

            return { response, body };
        }
        catch (e) {
            console.error(e);
        }
    }

}
class Withdraw{
    constructor(path){
        this.localPath = `${path}/withdraw`;
    }

    async withdraw(walletId, ticker){
        return await fetch(`${this.localPath}`, {
            method: "POST",
            body: JSON.stringify({
                walletId: walletId,
                ticker: ticker
            }),
            headers: { 'content-type': 'application/json' }
        });
    }

    async claimWithdrawal(withdrawId, tx){
        return await fetch(`${this.localPath}/${withdrawId}/claim`, {
            method: "POST",
            body: JSON.stringify({
                transaction: tx,
            }),
            headers: { 'content-type': 'application/json' }
        });
    }

}
class Payouts{
    constructor(path){
        this.localPath = `${path}/payouts`;
    }

    async get(walletId){
        return await fetch(`${this.localPath}?walletAddress=${walletId}`);
    }
}
class Ledger{
    constructor(path){
        this.localPath = `${path}/ledger`;
    }

    async sign(walletId){
        return await fetch(`${this.localPath}/${walletId}`);
    }
}
class Fix{
    constructor(path){
        this.localPath = `${path}/fix`;
    }

    async fix(walletAddress, mintToken){
        return await fetch(`${this.localPath}/${mintToken}`, {
            method: "POST",
            body: JSON.stringify({
                walletAddress: walletAddress,
            }),
            headers: { 'content-type': 'application/json' }
        });
    }

    async claim(mintToken, tx){
        return await fetch(`${this.localPath}/${mintToken}/claim`, {
            method: "POST",
            body: JSON.stringify({
                transaction: tx,
            }),
            headers: { 'content-type': 'application/json' }
        });
    }

}

const API = {
    data() {
        let path = process.env.VUE_APP_API_URL || "https://api.dangervalley.com";
        return {
            Wallet: new Wallet(path + "/api/v1"),
            Stake: new Stake(path + "/api/v3"),
            Withdraw: new Withdraw(path + "/api/v2"),
            Payouts: new Payouts(path + "/api/v3"),
            Ledger: new Ledger(path + "/api/v2"),
            Escrow: new Escrow(path + "/api/v4"),
            Fix: new Fix(path + "/api/v3"),
        }
    }
}

export default { API };