<template>
	<div class="page">
		<Header
			@onClickOnConnectWallet="togglePopup('connectWalletPopup')"
			@onClickOnMyPayouts="togglePopup('myPayoutsPopup')"
			:walletIdProp="walletId"
		/>

		<main class="main">

			<div id="bgImage1">
				<article class="article">
					<h1 class="article__heading">
						Earn $BEAK by staking JPEGs
					</h1>
					<span class="article__text">
						Non-custodial staking (NFTs remain in your wallet).
						<br />
						Min staking period - 72 hours. Your NFT will be locked during this period.
                        <br /><br />
                        We migrated staking to <a href="https://dv.sololabs.io/" target="_blank">https://dv.sololabs.io/</a>.
                        Please unstake your NFTs and stake them again on the new platform.
					</span>

					<div class="article__actions">
						<span
							v-if="!walletId"
							@click="togglePopup('connectWalletPopup')"
							class="btn btn--white"
						>Connect Wallet</span>
						<!-- <span v-if="walletId" @click="stakeAllDucks()"  class="btn btn--white">Stake all my NFTs</span> -->
						<span
							v-if="walletId"
							@click="togglePopup('withdrawPopup')"
							class="btn btn--transparent"
						>Claim rewards</span>
						<span
							@click="openChess()"
							class="btn btn--transparent"
						>Play Chess</span>
					</div>
				</article>

				<img
					src="@/assets/duck1.png"
					id="main__img"
				/>
			</div>

			<section class="wallet">
				<h2 class="wallet__heading">Staking in stats</h2>
				<div class="stats">
					<Stat
						v-for="stat of stats"
						:Stat="stat"
						:key="stat"
					/>
				</div>

				<div class="ducks">
					<Duck
						v-for="duck of ducks"
						:Duck="duck"
						:key="duck"
						@stake="stakeDuck"
						@unstake="unstakeDuck"
						@fix="fixDuck"
					/>
				</div>
			</section>

		</main>

		<section
			class="popup__wrapper"
			id="withdrawPopup"
		>
			<div class="popup">
				<img
					class="popup__close"
					src="@/assets/cross.svg"
					@click="togglePopup('withdrawPopup')"
				/>
				<h1 class="popup__heading">Claim rewards</h1>
				<span class="popup__text">

					<template v-if="balance.SOL > 0">
						Claim {{ balance.SOL }} SOL
						<template v-if="balance.BEAK > 0">
							and {{ balance.BEAK }} BEAK
						</template>
						.
					</template>

					<template v-if="balance.SOL == 0 && balance.BEAK > 0">
						Claim {{ balance.BEAK }} BEAK
					</template>

					<template v-if="balance.SOL == 0 && balance.BEAK == 0">
						Nothing to claim
					</template>

				</span>
				<div class="popup__withdraw">
					<span
						class="btn btn--withdraw"
						v-if="balance.SOL > 0"
						@click="withdraw('SOL')"
					>Claim SOL</span>
					<span
						class="btn btn--withdraw"
						v-if="balance.BEAK > 0"
						@click="withdraw('BEAK')"
					>Claim BEAK</span>
				</div>
			</div>
		</section>

		<section
			class="popup__wrapper"
			id="connectWalletPopup"
		>
			<div class="popup">
				<img
					class="popup__close"
					src="@/assets/cross.svg"
					@click="togglePopup('connectWalletPopup')"
				/>
				<h1 class="popup__heading">Select wallet</h1>
				<div class="popup__wallets">

					<div
						class="selectableWallet"
						@click="connectWallet('Phantom')"
					>
						<span class="selectableWallet__type">Phantom</span>
						<img
							class="selectableWallet__img"
							src="@/assets/Phantom.png"
						/>
					</div>

					<div
						class="selectableWallet"
						@click="connectWallet('Solflare')"
					>
						<span class="selectableWallet__type">Solflare</span>
						<img
							class="selectableWallet__img"
							src="@/assets/Solflare.png"
						/>
					</div>

					<div
						class="selectableWallet"
						@click="connectWallet('Slope')"
					>
						<span class="selectableWallet__type">Slope</span>
						<img
							class="selectableWallet__img"
							src="@/assets/Slope.png"
						/>
					</div>

					<div id="usingLedger">
						<Toggle v-model="usingLedger" />
						<span>Using Ledger?</span>
					</div>

				</div>
			</div>
		</section>

		<section
			class="popup__wrapper"
			id="myPayoutsPopup"
		>
			<div class="popup">
				<img
					class="popup__close"
					src="@/assets/cross.svg"
					@click="togglePopup('myPayoutsPopup')"
				/>
				<h1 class="popup__heading">My payouts</h1>
				<span class="popup__text">
					<div
						class="payout"
						v-for="payout of payouts"
						:key="payout"
					>
						<span class="payout__date">{{ payout.date }}</span>
						<span class="payout__amount">{{ payout.amount }}</span>
					</div>
					<span v-if="!payouts.length">There are no payouts yet :(</span>
				</span>
			</div>
		</section>
	</div>
</template>

<script>
import { Buffer } from 'buffer';
import Toggle from '@vueform/toggle';
import Header from '@/components/Header.vue'
import Duck from '@/components/Duck.vue'
import Stat from '@/components/Stat.vue'
import bs58 from 'bs58';
import * as web3 from '@solana/web3.js';

export default {
	name: 'Home',
	components: {
		Header,
		Duck,
		Stat,
		Toggle
	},
	data: function () {
		return {
			walletId: undefined,
			stats: undefined,
			ducks: undefined,
			balance: {
				SOL: 0,
				BEAK: 0
			},
			canClick: true,
			popup: undefined,
			publicKey: undefined,
			adapter: undefined,
			chosenWallet: undefined,
			solletWallet: undefined,
			payouts: [],
			usingLedger: false
		}
	},
	mounted: function () {
		this.getStats();
	},
	methods: {
		openChess() {
			window.open('https://xchess.io', '_blank');
		},
		async showToast(msg, type = "success", dur = "3000") {
			const tmp = this.$toast.open({
				message: msg,
				type: type,
				dur: dur
			});
			this.popup = tmp;
			return tmp;
		},
		togglePopup(id) {
			console.log(id);
			if (id == "myPayoutsPopup") this.getPayouts();
			document.querySelector(`#${id}`).classList.toggle("active");
		},
		async connectWallet(type) {
			switch (type) {
				case "Phantom":
					if (window.solana) {
						let resp = await window.solana.connect();
						this.publicKey = resp.publicKey;
						this.walletId = this.publicKey.toString();
						this.chosenWallet = "solana";
						this.getWalletInfo();
						document.querySelector(`#connectWalletPopup`).classList.remove("active");
						return true;
					}
					else window.open("https://phantom.app/");
					break;
				case "Solflare":
					if (window.solflare) {
						await window.solflare.connect();
						this.publicKey = window.solflare.publicKey;
						this.walletId = this.publicKey.toString();
						this.chosenWallet = "solflare";
						this.getWalletInfo();
						document.querySelector(`#connectWalletPopup`).classList.remove("active");
						return true;
					}
					else window.open("https://solflare.com/");
					break;
				case "Slope":
					if (typeof window.Slope == "function") {
						let slope = new window.Slope();
						let resp = await slope.connect();
						if (resp.msg == "Cancelled") return;
						this.publicKey = resp.data.publicKey;
						this.walletId = this.publicKey;
						this.chosenWallet = "slope";
						this.getWalletInfo();
						document.querySelector(`#connectWalletPopup`).classList.remove("active");
						return true;
					}
					else window.open("https://slope.finance/");
					break;
				default:
					this.showToast("No wallets found!", "error", 10000);
					this.showToast("Supported wallets: Phantom, Solflare, Slope", "info", 10000);
					return false;
					break;
			}
		},
		async signMessage(msg) {
			if (this.usingLedger) {
				let tx = await this.ledgerSign();
				return tx;
			}
			else {
				try {
					const message = new TextEncoder().encode(msg);
					let signature;
					switch (this.chosenWallet) {
						case "solana":
							signature = (await window.solana.signMessage(message)).signature;
							break;
						case "solflare":
							signature = (await window.solflare.signMessage(message)).signature;
							break;
						case "slope":
							signature = (await (new window.Slope()).signMessage(message, 'utf8')).data.signature;
							break;
						default:
							break;
					}

					if (this.chosenWallet == 'slope') {
						return signature;
					}

					return await bs58.encode(signature);
				} catch (error) {
					console.error(error);
					alert(`Signing failed: ${error.message}`);
					return false;
				}
			}
		},
		async partialSignTransaction(encodedTransctionString) {
			try {
				const encodedTransction2 = Buffer.from(JSON.parse(encodedTransctionString));
				let transaction = web3.Transaction.from(encodedTransction2);
				let signedTx;

				switch (this.chosenWallet) {
					case "solana":
						signedTx = await window.solana.signTransaction(transaction);
						break;
					case "solflare":
						signedTx = (await window.solflare.signTransaction(transaction));
						break;
					case "slope":
						const { msg, data } = await (new window.Slope()).signTransaction(
							bs58.encode(transaction.serializeMessage())
						)

						if (msg === 'ok') {
							transaction.addSignature(
								new web3.PublicKey(data.publicKey),
								bs58.decode(data.signature)
							)

							signedTx = transaction;
						}

						break;
					default:
						break;
				}

				if (signedTx == undefined) {
					return undefined;
				}

				let resEncodedTransction = signedTx.serialize({
					requireAllSignatures: false,
					verifySignatures: false,
				});

				const resEncodedTransctionString = JSON.stringify(resEncodedTransction.toJSON());

				return resEncodedTransctionString;
			} catch (error) {
				console.error(error);
				if (error && error.message) {
					this.showToast(`Signing failed: ${error.message}`, "error");
				}
				return false;
			}
		},
		// API
		async getWalletInfo() {
			let resp = await this.$options.API.data().Wallet.get(this.walletId);
			this.ducks = (await resp.json()).nfts;
			this.getStats(this.walletId);
			this.getPayouts();
		},
		async ledgerSign() {
			let resp = await this.$options.API.data().Ledger.sign(this.walletId);
			let encodedTransctionString = (await resp.json()).transaction;

			const signedTx = await this.partialSignTransaction(encodedTransctionString);
			console.log('signedTx', signedTx);

			return signedTx;
		},
		async stake(mints) {
			this.showToast("Wait, please", "info", 0);
			let resp = await this.$options.API.data().Stake.stake(this.walletId, mints);
			let body = await resp.json();

			if (resp.status != 200) {
				this.popup.dismiss();
				this.showToast(body.errors[0].message, "error");
				return;
			}

			if (!body.transaction) {
				this.popup.dismiss();
				return;
			}

			const signedTx = await this.partialSignTransaction(body.transaction);
			console.log('signedTx', signedTx);

			if (!signedTx) {
				this.popup.dismiss();
				this.showToast("Try again", "error");
				return;
			}

			let respClaim = await this.$options.API.data().Escrow.claim([body.id], [signedTx]);
			let bodyClaim = await respClaim.json();

			if (respClaim.status != 200) {
				this.popup.dismiss();
				this.showToast(bodyClaim.errors[0].message, "error");
				return;
			}

			const escrowIds = bodyClaim.statusses.map(msg => msg.id);

			this.popup.dismiss();

			const confirmPopup = await this.showToast("Confirming transaction", "info", 0);
			this.checkEscrowStatusUntilCompleted(escrowIds, confirmPopup);
		},
		async checkEscrowStatusUntilCompleted(escrowIds, confirmPopup) {
			setTimeout(async () => {
				console.log('checkEscrowStatusUntilCompleted');
				let { response, body } = await this.$options.API.data().Escrow.getStatus(escrowIds);

				if (response == undefined) {
					confirmPopup.dismiss();
					this.showToast("Try again", "error");
					return;
				}

				if (response.status != 200) {
					confirmPopup.dismiss();
					this.showToast(body.errors[0].message, "error");
					return;
				}

				body.statusses.forEach(async el => {
					if (el.status == 'COMPLETED') {
						confirmPopup.dismiss();
						this.showToast("Staked!");
						this.getWalletInfo();
						return;
					}
					else if (el.status == 'ERROR') {
						confirmPopup.dismiss();
						this.showToast("Something went wrong. Try again later", "error");
						this.getWalletInfo();
						return;
					}
					else if (el.status == 'PROCESSING') {
						// ONCE AGAIN
						this.checkEscrowStatusUntilCompleted(escrowIds, confirmPopup);
					}
				});

			}, 1000);

		},
		async stakeAllDucks() {
			if (this.canClick) {
				let mints = this.ducks.map(el => el.mintToken);
				this.stake(mints);
			}
		},
		async stakeDuck(duckMintToken) {
			if (this.canClick) {
				this.stake([duckMintToken]);
			}
		},
		async fixDuck(mintToken) {
			if (this.canClick) {
				this.showToast("Wait, please", "info", 0);
				let resp = await this.$options.API.data().Fix.fix(this.walletId, mintToken);
				let body = await resp.json();

				// console.log('fixDuck', 'resp.status:', resp.status);
				// console.log('fixDuck', 'fix response body:', body);

				if (resp.status != 200) {
					this.popup.dismiss();
					this.showToast(body.errors[0].message, "error");
					return;
				}

				if (!body.transaction) {
					// console.log('fixDuck', '!body.transaction');

					this.popup.dismiss();
					return;
				}

				// console.log('fixDuck', 'partialSignTransaction');

				const signedTx = await this.partialSignTransaction(body.transaction);
				// console.log('signedTx', signedTx);

				if (!signedTx) {
					this.popup.dismiss();
					this.showToast("Try again", "error");
					return;
				}

				let respClaim = await this.$options.API.data().Fix.claim(mintToken, signedTx);
				let bodyClaim = await respClaim.json();

				if (respClaim.status != 200) {
					this.popup.dismiss();
					this.showToast(bodyClaim.errors[0].message, "error");
					return;
				}

				const escrowId = bodyClaim.id;

				this.popup.dismiss();

			}
		},
		async unstakeDuck(duckMintToken) {
			if (this.canClick) {
				const mints = [duckMintToken];

				this.showToast("Wait, please", "info", 0);
				let resp = await this.$options.API.data().Stake.unstake(this.walletId, mints);
				let body = await resp.json();

				if (resp.status != 200) {
					this.popup.dismiss();
					this.showToast(body.errors[0].message, "error");
					return;
				}

				if (!body.transaction) {
					this.popup.dismiss();
					this.getWalletInfo();
					return;
				}

				const signedTx = await this.partialSignTransaction(body.transaction);
				console.log('signedTx', signedTx);

				if (!signedTx) {
					this.popup.dismiss();
					this.showToast("Try again", "error");
					return;
				}

				let respClaim = await this.$options.API.data().Escrow.claim([body.id], [signedTx]);
				let bodyClaim = await respClaim.json();

				if (respClaim.status != 200) {
					this.popup.dismiss();
					this.showToast(bodyClaim.errors[0].message, "error");
					return;
				}

				const escrowIds = bodyClaim.statusses.map(msg => msg.id);

				this.popup.dismiss();

				const confirmPopup = await this.showToast("Confirming transaction", "info", 0);
				this.checkEscrowStatusUntilCompleted(escrowIds, confirmPopup);
			}
		},
		async getStats(walletId = this.walletId) {
			let resp = await this.$options.API.data().Stake.get(walletId);
			let body = await resp.json();
			this.stats = body.stats;
			this.balance = {
				SOL: +body.balance,
				BEAK: +body.beakBalance
			};
		},
		async withdraw(ticker) {
			if (this.canClick) {
				this.showToast("Wait, please", "info", 0);
				let resp = await this.$options.API.data().Withdraw.withdraw(this.walletId, ticker);
				this.popup.dismiss();
				let body = await resp.json();
				console.log('Withdraw response body:', body);

				if (resp.status == 200) {
					let withdrawId = body.withdrawId;
					let encodedTransctionString = body.transaction;

					const signedTx = await this.partialSignTransaction(encodedTransctionString);
					console.log('signedTx', signedTx);

					if (signedTx != undefined) {
						let claimResp = await this.$options.API.data().Withdraw.claimWithdrawal(withdrawId, signedTx);
						let claimBody = await claimResp.json();
					}

					this.togglePopup('withdrawPopup');
					this.balance = 0;
				}
				else this.showToast(body.errors[0].message, "error");
			}
		},
		async getPayouts() {
			let resp = await this.$options.API.data().Payouts.get(this.walletId);
			let body = await resp.json();
			this.payouts = body.payouts;
		}
	}
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>

<style lang="scss">
#bgImage1 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	background-image: url("../assets/bg.png");
	background-color: #000;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 73px clamp(20px, 10vw, 230px) 73px clamp(20px, 10vw, 130px);
}

.main {
	background-color: #fff;
}

.article {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;
	color: #fff;

	&__heading {
		font-family: Lato-Bold;
		font-size: 44px;
		margin: unset;
		max-width: 380px;
	}

	&__text {
		margin: 20px 0;
		font-size: 16px;
		line-height: 24px;
		max-width: 380px;
	}

	&__actions {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		justify-content: center;
	}
}

#main__img {
	width: clamp(100px, 40vw, 340px);
	border-radius: 10px;
}

.wallet {
	margin-top: 100px;

	&__heading {
		font-size: 26px;
		margin: 0;
	}
}

.stats {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 70px;
}

.ducks {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin: 120px;
	row-gap: 53px;
	gap: 60px;
}

.popup {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
	position: relative;
	top: 100px;
	width: fit-content;
	border-radius: 24px;
	background-color: #fff;
	padding: 32px 20px;
	transition: .25s;
	transform: scale(0);
	min-width: 320px;
	max-height: 60vh;
	height: fit-content;

	&__wrapper {
		&.active {
			transform: scale(1);

			>.popup {
				transform: scale(1);
			}
		}

		transform: scale(0);
		transform-origin: 50% 0;
		display: flex;
		justify-content: center;
		z-index: 999;
		position: fixed;
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;
		background-color: #000000b3;
		//transform: scale(0);
	}

	&__heading {
		font-size: 44px;
		font-weight: 700;
		margin: 4px 0;
	}

	&__text {
		width: 100%;
		font-size: 16px;
		margin: 22px 0;
		line-height: 24px;
		overflow: auto;
	}

	&__close {
		cursor: pointer;
		position: absolute;
		top: 20px;
		right: 20px;
	}

	&__wallets {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 23px;
	}

	&__withdraw {
		display: flex;
		flex-direction: row;
		gap: 5px;
	}
}

.selectableWallet {
	width: 100%;
	min-width: 328px;
	box-sizing: border-box;
	padding: 9px 22px;
	margin: 5px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #0000004d;
	border-radius: 9px;
	cursor: pointer;
	transition: .75s;

	&:hover {
		box-shadow:
			2px 0 4px #1C1C1C60,
			-2px 0 4px #1C1C1C60,
			0 4px 4px #1C1C1C60;
	}

	&__type {
		font-weight: 600;
		font-size: 12px;
	}

	&__img {
		width: 25px;
	}
}

@font-face {
	font-family: Roboto;
	src: url("~@/assets/Roboto-Regular.ttf");
}

.payout {
	width: 100%;
	display: flex;
	justify-content: space-between;
	font-family: Roboto;
	font-size: 20px;
	line-height: 28px;
	padding-bottom: 12px;

	&__date {
		color: #130f1c;
	}

	&__amount {
		color: #7E7D80;
	}
}

#usingLedger {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin: 5px 0;

	.toggle-container {
		padding-right: 12px;

		&:focus {
			box-shadow: none !important;
		}
	}
}

@media (max-width: 950px) {
	#main__img {
		display: none;
	}

	#bgImage1 {
		justify-content: center;
		padding: 73px 20px;
	}

	.stats {
		flex-direction: column;
		margin-top: 0;

		.stat {
			margin: 20px;
		}
	}

	.ducks {
		flex-direction: column;
		margin: 40px 0;
	}
}
</style>