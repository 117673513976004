<template>
  	<router-view/>
</template>

<style lang="scss">
@font-face {
	font-family: Lato-Regular;
	src: url("./assets/Lato-Regular.ttf");
}
@font-face {
	font-family: Lato-Bold;
	src: url("./assets/Lato-Bold.ttf");
}

body{
	padding: 0;
	margin: 0;
	overflow-x: hidden;
}

#app {
	font-family: Lato-Regular;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}

.page{
	width: 100vw;
	min-height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.btn{
	font-size: 12px;
	border-radius: 9px;
	text-align: center;
	cursor: pointer;
	transition: .75s;
	$modifier: 15%;
	&:hover{
		box-shadow:
			2px 0 4px #1C1C1C60,
			-2px 0 4px #1C1C1C60,
			0 4px 4px #1C1C1C60;
	}
	&--blue{
		color: #fff;
  		background-color: #2081E2;

		padding: 10.5px 18px;
		&:hover{
			background-color: lighten(#2081E2, $modifier);
		}
	}
	&--white{
		color: #1B232E;
		background-color: #fff;
		&:hover{
			background-color: darken(#fff, $modifier);
		}

		padding: 15px 39px;
		box-sizing: border-box;
		font-weight: 600;
	}
	&--transparent{
		color: #fff;
		background-color: transparent;
		border: 1px solid #ffffff4d;
		&:hover{
			background-color: #ffffff40;
		}

		padding: 15px 39px;
		box-sizing: border-box;
		font-weight: 600;
	}
	&--multicolor{
		color: #fff;
		background: linear-gradient(85.35deg, #914CF9 -59.33%, #06CB91 170.37%);

		padding: 8px 12px;
		box-sizing: border-box;
		font-weight: 600;
		border-radius: 20px;
	}
	&--unstake{
		color: #1C1C1C;
		background: transparent;
		border: 1px solid #1C1C1C1A;

		padding: 8px 12px;
		box-sizing: border-box;
		font-weight: 600;
		border-radius: 20px;
	}
	&--withdraw{
		background: linear-gradient(85.35deg, #914CF9 -59.33%, #06CB91 170.37%);
		color: #fff;

		padding: 8px 22px;
		box-sizing: border-box;
		font-weight: 600;
		border-radius: 20px;
	}
}
</style>
