import { createApp, VueElement } from 'vue'
import App from './App.vue'
import router from './router'
import API from "./mixins/api.js";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

let app = 
    createApp(App)
    .use(router)
    .use(VueToast)
    .mixin(API);

app.config.compilerOptions.isCustomElement = tag => tag.startsWith('wallet')

app.mount('#app');